/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { Box, CircularProgress } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { useRequester } from '../../hooks';
import { FeedbackLevelData, FeedbackLevelResource } from '../../resources';
import FeedbackLevelForm from './FeedbackLevelForm';

export default function FeedbackLevelEditPage() {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();

  const fetch = useRequester<FeedbackLevelResource>({
    onSubmit: (id) => FeedbackLevelResource.fetchOne(id),
    onError: console.log,
  });

  const save = useRequester<FeedbackLevelResource>({
    onSubmit: (data: any) =>
      id
        ? FeedbackLevelResource.updateOne(id, data)
        : FeedbackLevelResource.createOne(data),
    onSuccess: (ob) => navigate(`/feedback-levels`),
    onError: console.log,
  });

  useEffect(() => {
    id && fetch.submit(id);
  }, [id]);

  let type;
  if (id) type = FeedbackLevelResource.get(id).data as FeedbackLevelData;

  if (id && !type) {
    return (
      <Box className="flex flex-col items-center h-full justify-center">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box>
      <FeedbackLevelForm
        label={id ? 'Edit Level' : 'New Level'}
        data={type}
        onSubmit={save.submit}
        saving={save.loading}
        error={save.error}
      />
    </Box>
  );
}
