/* eslint-disable react-hooks/exhaustive-deps */
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Divider,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import {
  Delete as DeleteIcon,
  SaveRounded as SaveIcon,
} from '@mui/icons-material';
import { Form } from '../../design';
import { useRequester } from '../../hooks';
import { FeedbackLevelData, FeedbackLevelResource } from '../../resources';

type Props = {
  onSubmit: (data: any) => void;
  saving?: boolean;
  data?: FeedbackLevelData;
  error?: string;
  label: string;
};

const makeRequestBody = (data: any) => {
  const body: any = {
    name: data.name,
    ranges: [],
    levels: [],
  };

  for (const key in data) {
    if (key.startsWith('ranges') || key.startsWith('levels')) {
      const [pref, attr, index] = key.split('_');
      if (!body[pref][index]) {
        body[pref][index] = { text: {} };
      }

      if (['en', 'ja'].includes(attr)) {
        body[pref][index].text[attr] = data[key];
      } else if (['min', 'max'].includes(attr)) {
        body[pref][index][attr] = parseFloat(data[key]);
      }
    }
  }

  // If any range is missing min or max, remove it
  body.ranges = body.ranges.filter(
    (range: any) => !isNaN(range.min) && !isNaN(range.max)
  );
  body.levels = body.levels.filter(
    (level: any) => !isNaN(level.min) && !isNaN(level.max)
  );

  // Sort ranges by min
  body.ranges = body.ranges.sort((a: any, b: any) => a.min - b.min);
  body.levels = body.levels.sort((a: any, b: any) => a.min - b.min);

  // Assign level to each range
  body.ranges.forEach((range: any, index: number) => {
    range.level = index + 1;
  });
  body.levels.forEach((level: any, index: number) => {
    level.level = index + 1;
  });

  return body;
};

export default function FeedbackLevelForm({
  label,
  onSubmit,
  saving,
  data,
  error,
}: Props) {
  const [ranges, setRanges] = useState(data?.ranges || []);
  const [levels, setLevels] = useState(data?.levels || []);

  const fetch = useRequester<FeedbackLevelResource[]>({
    onSubmit: () => FeedbackLevelResource.fetchMany(),
    onError: console.log,
  });

  useEffect(() => {
    fetch.submit();
  }, []);

  const handleSubmit = async (data: FeedbackLevelData) => {
    const body = makeRequestBody(data);
    onSubmit(body);
  };

  const busy = saving;

  return (
    <Form
      onSubmit={handleSubmit}
      className="flex flex-col gap-4"
      disabled={busy}
    >
      <Typography variant="h6" sx={{ lineHeight: 1 }}>
        {label}
      </Typography>
      <Divider />
      <Box className="flex">
        <Box className="flex-grow">
          <Box className="grid grid-cols-3 gap-4">
            <TextField
              label="Name"
              name="name"
              defaultValue={data?.name}
              required
            />
          </Box>
        </Box>
      </Box>

      <Divider />

      <Box className="grid grid-cols-2 gap-8">
        <Box className="flex flex-col gap-4">
          {levels.map((level, index) => (
            <Box
              key={`${levels.length}_${index}`}
              className="flex flex-col gap-4"
            >
              <Box display="flex" sx={{ alignItems: 'center' }}>
                <Typography variant="subtitle2">
                  Eiken Level {index + 1}
                </Typography>
                <IconButton
                  size="small"
                  color="error"
                  onClick={() => {
                    setLevels(levels.filter((r, i) => i !== index));
                  }}
                >
                  <DeleteIcon fontSize="small" />
                </IconButton>
              </Box>
              <Box className="grid grid-cols-3 gap-4">
                <TextField
                  type="number"
                  label="Min"
                  defaultValue={level.min}
                  name={`levels_min_${index}`}
                  inputProps={{
                    step: 1,
                    min: 0,
                  }}
                  required
                />
                <TextField
                  type="number"
                  label="Max"
                  defaultValue={level.max}
                  name={`levels_max_${index}`}
                  inputProps={{
                    step: 1,
                    min: 0,
                  }}
                  required
                />
              </Box>
              <Box className="grid grid-cols-1 gap-4">
                <TextField
                  label="Text (en)"
                  defaultValue={level.text.en}
                  name={`levels_en_${index}`}
                  required
                />
                <TextField
                  label="Text (ja)"
                  defaultValue={level.text.ja}
                  name={`levels_ja_${index}`}
                  required
                />
              </Box>
              <Divider />
            </Box>
          ))}
          <Box>
            <Button
              onClick={() => {
                setLevels([
                  ...levels,
                  {
                    level: levels.length + 1,
                    min:
                      levels.length > 0 ? levels[levels.length - 1].max + 1 : 0,
                    max:
                      levels.length > 0
                        ? levels[levels.length - 1].max + 10
                        : 10,
                    text: { en: '', ja: '' },
                  },
                ]);
              }}
            >
              Add Level
            </Button>
          </Box>
          <Divider />
        </Box>

        <Box className="flex flex-col gap-4">
          {ranges.map((range, index) => (
            <Box
              key={`${ranges.length}_${index}`}
              className="flex flex-col gap-4"
            >
              <Box display="flex" sx={{ alignItems: 'center' }}>
                <Typography variant="subtitle2">Range {index + 1}</Typography>
                <IconButton
                  size="small"
                  color="error"
                  onClick={() => {
                    setRanges(ranges.filter((r, i) => i !== index));
                  }}
                >
                  <DeleteIcon fontSize="small" />
                </IconButton>
              </Box>
              <Box className="grid grid-cols-3 gap-4">
                <TextField
                  type="number"
                  label="Min"
                  defaultValue={range.min}
                  name={`ranges_min_${index}`}
                  inputProps={{
                    step: 1,
                    min: 0,
                  }}
                  required
                />
                <TextField
                  type="number"
                  label="Max"
                  defaultValue={range.max}
                  name={`ranges_max_${index}`}
                  inputProps={{
                    step: 1,
                    min: 0,
                  }}
                  required
                />
              </Box>
              <Box className="grid grid-cols-1 gap-4">
                <TextField
                  label="Text (en)"
                  defaultValue={range.text.en}
                  name={`ranges_en_${index}`}
                  required
                />
                <TextField
                  label="Text (ja)"
                  defaultValue={range.text.ja}
                  name={`ranges_ja_${index}`}
                  required
                />
              </Box>
              <Divider />
            </Box>
          ))}
          <Box>
            <Button
              onClick={() => {
                setRanges([
                  ...ranges,
                  {
                    level: ranges.length + 1,
                    min:
                      ranges.length > 0 ? ranges[ranges.length - 1].max + 1 : 0,
                    max:
                      ranges.length > 0
                        ? ranges[ranges.length - 1].max + 10
                        : 10,
                    text: { en: '', ja: '' },
                  },
                ]);
              }}
            >
              Add Range
            </Button>
          </Box>
          <Divider />
        </Box>
      </Box>

      <Box className="flex flex-row gap-4">
        <Button
          size="large"
          startIcon={
            busy ? (
              <CircularProgress size={24} />
            ) : (
              <SaveIcon sx={{ height: 24, width: 24 }} />
            )
          }
          type="submit"
          variant="contained"
          disabled={busy}
        >
          Save
        </Button>
        {error && <Alert severity="error">{error}</Alert>}
      </Box>
    </Form>
  );
}
