import BaseResource from './BaseResource';

interface FeedbackLevelRange {
  level: number;
  min: number;
  max: number;
  text: {
    ja: string;
    en: string;
  };
}

export interface FeedbackLevelData {
  id: string;
  name: string;
  ranges: FeedbackLevelRange[];
  levels: FeedbackLevelRange[];
}

class FeedbackLevelResource extends BaseResource<FeedbackLevelData> {
  static get storeName(): string {
    return 'FeedbackLevelResource';
  }

  static get endpoint(): string {
    return `/${this.scope}/feedback-levels`;
  }

  static toObject(raw: any): FeedbackLevelResource {
    raw.id = raw.name;
    return new FeedbackLevelResource(raw as FeedbackLevelData);
  }
}

export default FeedbackLevelResource;
