/* eslint-disable react-hooks/exhaustive-deps */
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Typography,
} from '@mui/material';

import { useNavigate } from 'react-router-dom';
import { useConfirmation, useRequester } from '../../hooks';
import { useEffect } from 'react';
import { Add as AddIcon } from '@mui/icons-material';
import { Table } from '../../design';
import { FeedbackLevelData, FeedbackLevelResource } from '../../resources';

const columns = [
  { id: 'name', label: 'Name', minWidth: 170 },
  { id: 'levels', label: 'Eiken Levels' },
  { id: 'ranges', label: 'Ranges' },
];

export default function FeedbackLevelsPage() {
  const navigate = useNavigate();
  const deleteConfirmation = useConfirmation<FeedbackLevelData>({
    getTitle: (fl) => `Delete type '${fl.name}?'`,
    getDescription: (fl) =>
      `You are about to delete all levels for '${fl.name}'. This action cannot be undone.`,
    onConfirm: async (fl) => {
      await FeedbackLevelResource.deleteOne(fl.id);
    },
  });

  const api = useRequester<FeedbackLevelResource[]>({
    onSubmit: () => FeedbackLevelResource.fetchMany(),
    onError: console.log,
  });

  useEffect(() => {
    api.submit();
  }, []);

  const rows = FeedbackLevelResource.list().map(
    (fl: FeedbackLevelResource) => ({
      id: fl.data.id,
      name: fl.data.name,
      ranges:
        `${fl.data.ranges.length} ranges: [` +
        fl.data.ranges
          .map((range) => `${range.min} - ${range.max}`)
          .join(', ') +
        ']',
      levels:
        `${fl.data.levels.length} levels: [` +
        fl.data.levels
          .map((level) => `${level.min} - ${level.max}`)
          .join(', ') +
        ']',
    })
  );

  return (
    <Box>
      <Box className="flex justify-between">
        <Box className="flex gap-4">
          <Typography variant="h5" fontWeight="bold">
            Feedback Levels
          </Typography>
          {api.loading && (
            <Box className="flex items-center">
              <CircularProgress size={20} />
            </Box>
          )}
        </Box>
        <Button
          variant="contained"
          endIcon={<AddIcon />}
          onClick={() => navigate('/feedback-levels/new')}
        >
          New Level
        </Button>
      </Box>
      <Box className="h-4" />

      {api.error ? (
        <Alert severity="error">{api.error}</Alert>
      ) : (
        <Table
          columns={columns}
          rows={rows}
          onRowClick={(row) => navigate(`/feedback-levels/${row.id}/edit`)}
          onEdit={(row) => navigate(`/feedback-levels/${row.id}/edit`)}
          onDelete={(row) =>
            deleteConfirmation.triggerFor(row as FeedbackLevelData)
          }
        />
      )}
    </Box>
  );
}
