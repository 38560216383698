import BaseResource from './BaseResource';

export interface QuestionData {
  id: string;
  content: string;
  image: string;
  type: string;
  referenceText: string;
}

class QuestionResource extends BaseResource<QuestionData> {
  static get storeName(): string {
    return 'QuestionResource';
  }

  static get endpoint(): string {
    return `/${this.scope}/interviews/questions`;
  }

  static toObject(raw: any): QuestionResource {
    return new QuestionResource(raw as QuestionData);
  }
}

export default QuestionResource;
