import { Close as CloseIcon, Save as SaveIcon } from '@mui/icons-material';
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { FileInput, Form } from '../../design';
import { useRequester } from '../../hooks';
import { BaseResource, QuestionData, QuestionResource } from '../../resources';
import { useState } from 'react';

type Props = {
  question?: QuestionData;
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
};

export default function QuestionFormModal({
  question,
  open,
  onClose,
  onSuccess,
}: Props) {
  const save = useRequester<QuestionResource>({
    onSubmit: (data: any) =>
      question
        ? QuestionResource.updateOne(question.id, data)
        : QuestionResource.createOne(data),
    onSuccess,
    onError: console.log,
  });

  const [image, setImage] = useState<File>();
  const [uploading, setUploading] = useState(false);

  const handleSubmit = async (data: any) => {
    setUploading(true);
    if (image) data.image = await BaseResource.upload(image, 'image');
    if (!data.image) delete data.image; // We don't want to send empty string

    await save.submit(data);
    setUploading(false);
  };

  const busy = save.loading || uploading;

  return (
    <Modal open={open} onClose={onClose}>
      <Box className="bg-white absolute top-1/2 left-1/2 w-[600px] transform -translate-x-1/2 -translate-y-1/2 p-8 gap-4 flex flex-col">
        <Box className="flex justify-between">
          <Box className="flex flex-row">
            <Typography variant="h5" sx={{ fontWeight: 500 }}>
              {question ? 'Edit Question' : 'New Question'}
            </Typography>
          </Box>
          <IconButton onClick={onClose} size="small">
            <CloseIcon fontSize="small" />
          </IconButton>
        </Box>

        <Form
          onSubmit={handleSubmit}
          className="flex flex-col gap-4"
          disabled={busy}
        >
          <FormControl size="small" fullWidth>
            <InputLabel id="select-type">Type</InputLabel>
            <Select
              labelId="select-type"
              name="type"
              label="Type"
              defaultValue={question?.type || ''}
            >
              <MenuItem value="1">Type 1</MenuItem>
              <MenuItem value="2">Type 2</MenuItem>
              <MenuItem value="3">Type 3</MenuItem>
              <MenuItem value="4">Type 4</MenuItem>
              <MenuItem value="pronunciation">Pronunciation</MenuItem>
            </Select>
          </FormControl>

          <TextField
            name="content"
            label="Question"
            fullWidth
            size="small"
            defaultValue={question?.content}
          />

          <FileInput
            size="small"
            label="Image"
            name="image"
            accept="image/*"
            defaultValue={question?.image}
            onSelect={setImage}
          />

          <TextField
            name="referenceText"
            label="Reference Text"
            fullWidth
            size="small"
            defaultValue={question?.referenceText}
            multiline
          />

          <Divider />
          <Box className="flex flex-row gap-4">
            <Button
              startIcon={
                busy ? (
                  <CircularProgress size={20} />
                ) : (
                  <SaveIcon sx={{ height: 20, width: 20 }} />
                )
              }
              type="submit"
              variant="contained"
              disabled={busy}
            >
              Save
            </Button>
            {save.error && <Alert severity="error">{save.error}</Alert>}
          </Box>
        </Form>
      </Box>
    </Modal>
  );
}
